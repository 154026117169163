import React, {Component} from "react";
import {formatQuery} from "../controllers/utilities/string-utils";
import {connect} from "react-redux";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import {environmentMode} from "../components/lib/ad-manage-api-lib";
import iconGg from "../images/gg-logo.svg";
import Lambda from "../controllers/utilities/aws-lambda";
import {getFullPath} from "../controllers/kaisaku-api";
import cookie from "react-cookies";
import AndroidComm from "../controllers/utilities/unity-android-communication";

class GameSupport2 extends Component {
    constructor(props, context) {
        super(props, context);

        this.params = formatQuery(props.location.search);
        this.state = {
            ready: false,
            iframeKey:"chat-room",
            url:''
        };

    }


    async componentDidMount() {        
        const url = `${this.props.location.search}`;
        console.log(url);
        this.setState({
            url: url.replace("?url=", "")
        });
    }

    render() {
        // if(typeof window !== `undefined`) {
        //     const url = `/game-support/${this.props.location.search}`;
        //     window.location.href = url;
        // }
        return (
            <div style={{position:"absolute",left:"0px",top:"0px", right:"0px",bottom:"0px"}}>
                <header style={{
                    backgroundColor: "#ffffff",
                    color: "#FF3D00",
                    borderBottom: "solid 5px #FF3D00",
                    height: "45px",
                    font: "normal normal 900 22px/40px Segoe UI",
                    paddingLeft: "8px",
                    verticalAlign: "middle"
                }}>
                    <div style={{
                        float: "left"
                    }}>
                        {/*<Image src={logo} style={{*/}
                        {/*    paddingTop: "10px",*/}
                        {/*    verticalAlign: "middle"}}/>*/}
                    </div>
                    <div style={{
                        float: "right"
                    }}>
                    <a href="#"
                       onClick={async () => {
                        AndroidComm.sendRawMessage({
                            success: false,
                            message: "user returned"
                        });
                    }}
                       style={{
                        color: "#ffffff",
                        backgroundColor: "#AFAFAF",
                        borderRadius: "5px",
                        float: "right",
                        width: "80px",
                        height: "30px",
                        lineHeight: "40px",
                        marginTop: "5px",
                        marginRight: "10px",
                        font: "normal normal 600 14px/30px Segoe UI",
                        textAlign: "center",
                        verticalAlign: "middle"
                       }}>返回遊戲</a></div>
                </header>
            <div style={{position:"absolute",left:"0px",top:"45px", right:"0px",bottom:"0px"}}>
                <iframe key={this.state.iframeKey} src={this.state.url} style={{width:"100%", height:"100%"}} frameBorder="no" border="0"></iframe>
            </div>
                </div>
    );
    }
}

function mapStateToProps(state) {
    return {session: state.session};
}

export default connect(mapStateToProps)(GameSupport2);

